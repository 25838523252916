const formatter = (locale = 'en-US') => {
	return new Intl.DateTimeFormat(locale, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		// @TODO: This should be dynamic based on the user's timezone
		timeZone: 'America/Los_Angeles',
	})
}

export function formatDateStyleMedium(date: string, locale?: string) {
	return formatter(locale).format(new Date(date))
}
