import dynamic from 'next/dynamic'
import ReactHead from '@hashicorp/react-head'
import Hero from './components/hero'
import MobileHeader from './components/mobile-header'
import Panel from './components/panel'
import * as FSPrimitives from './primitives'
import type { FacetedSearchPageProps } from './types'

const LoadMoreResults = dynamic(() => import('./components/results-load-more'))
const PaginatedResults = dynamic(() => import('./components/results-paginated'))
const RESULTS_COMPONENT_MAP = {
	numbered: PaginatedResults,
	loadMore: LoadMoreResults,
}

export default function FacetedSearchPage({
	hero,
	panel,
	hitComponent,
	useSingleColumn = false,
	paginationType = 'loadMore',
	metaTags,
	children,
	transformItems,
	contentLabel,
	hiddenAttributes,
	...props
}: FacetedSearchPageProps) {
	const ResultsComponent = RESULTS_COMPONENT_MAP[paginationType]

	return (
		<>
			<ReactHead
				title={metaTags.title}
				description={metaTags.description}
				image={metaTags.image?.url}
				pageName={metaTags.title}
				twitterCard="summary_large_image"
			>
				<meta name="twitter:title" content={metaTags.title} />
				<meta name="twitter:description" content={metaTags.description} />
			</ReactHead>
			<FSPrimitives.Root
				{...props}
				panel={panel}
				paginationType={paginationType}
			>
				{hero && <Hero {...hero} />}
				{children}
				<FSPrimitives.Body>
					<FSPrimitives.MobileHeaderWrapper>
						<MobileHeader />
					</FSPrimitives.MobileHeaderWrapper>
					<FSPrimitives.PanelWrapper>
						<Panel
							{...panel}
							contentLabel={contentLabel}
							hiddenAttributes={hiddenAttributes}
						/>
					</FSPrimitives.PanelWrapper>
					<FSPrimitives.ResultsWrapper>
						<ResultsComponent
							transformItems={transformItems}
							contentLabel={contentLabel}
							hitComponent={hitComponent}
							useSingleColumn={useSingleColumn}
						/>
					</FSPrimitives.ResultsWrapper>
				</FSPrimitives.Body>
			</FSPrimitives.Root>
		</>
	)
}
