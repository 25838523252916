import {
	ResourceCard,
	type ResourceCardProps,
} from '@hashicorp/react-components/src/components/card/resource-card'
import {
	hasHashiCorpDestination,
	isInternal,
	parseUrl,
} from '@hashicorp/react-components/src/lib/parse-url'
import { formatDateStyleMedium } from 'lib/formatting/date-time'
import FacetedSearchPage from 'views/faceted-search'
import type { Products } from '@hashicorp/platform-product-meta'
import type { InferGetServerSidePropsType } from 'next'
import type { getServerSideProps } from './server'

export default function ResourcesPage({
	serverState,
	serverUrl,
	hero,
	paginationType,
	metaTags,
}: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element {
	return (
		<FacetedSearchPage
			contentType="resources"
			contentLabel="resource"
			metaTags={metaTags}
			hero={{
				...hero,
				searchBox: { label: 'Search by title' },
				toggleRefinement: {
					attribute: 'enterprise',
					label: 'Show only enterprise content',
				},
			}}
			paginationType={paginationType}
			panel={{
				fieldSets: [
					{ heading: 'Product', attribute: 'products', inputType: 'checkbox' },
					{ heading: 'Type', attribute: 'contentType', inputType: 'checkbox' },
					{ heading: 'Language', attribute: 'language', inputType: 'radio' },
					{ heading: 'Industry', attribute: 'industry', inputType: 'checkbox' },
					{
						heading: 'Infrastructure provider',
						attribute: 'infrastructureProvider',
						inputType: 'checkbox',
					},
				],
				toggleRefinement: {
					attribute: 'enterprise',
					label: 'Show only enterprise content',
				},
			}}
			transformItems={(items) => items.map((item) => formatResource(item))}
			serverState={serverState}
			serverUrl={serverUrl}
			hitComponent={ResourceCard}
		/>
	)
}

function formatResource(resource) {
	const {
		title,
		externalLink,
		slug,
		products,
		postDate,
		contentType,
		imageUrl,
	} = resource

	// The resourcesV2 model supports an `externalLink` field. This allows content authors
	// to provide a link that does not follow the /resources/[slug] pattern. This can include
	// both absolute hashicorp.com links and non-HashiCorp links (foo.com, bar.com, etc.).
	// In the case of the former, this appears to transform absolute hashicorp.com URLs
	// into relative URLs.
	function getExternalLinkPropsForResourceCard(link: string) {
		if (isInternal(link)) {
			return link.split('hashicorp.com')[1]
		}

		return link
	}

	// Check if a value for the externalLink field is provided and does not use a HashiCorp
	// destination.
	const isExternalDestination =
		!!externalLink && !hasHashiCorpDestination(externalLink)
	const link = externalLink
		? getExternalLinkPropsForResourceCard(externalLink)
		: `/resources/${slug}`

	const productBadges: Array<Products> = products.map((product) =>
		product.toLowerCase()
	)

	const date =
		postDate && typeof postDate === 'string'
			? formatDateStyleMedium(postDate)
			: null

	const category = contentType === 'Recorded Webinar' ? 'Webinar' : contentType

	const defaultImageUrl =
		'https://www.datocms-assets.com/2885/1599178066-events-social-share.jpg'
	const thumbnail = {
		src: (imageUrl || defaultImageUrl) + '?w=800&h=450&fit=crop',
		alt: '', // decorative element
	}

	const props: ResourceCardProps = {
		thumbnail,
		heading: title,
		link,
		productBadges,
		date,
		category,
		isExternal: isExternalDestination,
	}

	return {
		...resource,
		...props,
	}
}
